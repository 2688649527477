<template>
  <div class="delete-account">
    <div class="delete-account__form" v-if="step === 1 && !visibleLoader">
      <img src="@/assets/svg/logo/red.svg" alt="" class="delete-account__form-logo">
      <h1 class="delete-account__form-title">{{ $t('for-delete-account') }}</h1>
      <ui-input
          v-model="email"
          :error="$v.email.$error || catchError"
          :error-text='getLoginErrorText'
          @input="catchError = false"
          :label="$t('enter-your-email')"
      ></ui-input>
      <ui-button @click="submit" :disabled="disabled">
        {{ $t('send') }}
      </ui-button>
    </div>
    <UiLoader v-model="visibleLoader"/>
    <div class="delete-account__form" v-if="step === 2 && !visibleLoader">
      <img src="@/assets/svg/logo/red.svg" alt="" class="delete-account__form-logo">
      <h1 class="delete-account__form-title">{{ $t('for-delete-account') }}</h1>
      <ui-input
          :label="$t('enter-confirmation-code')"
          v-model="authCode"
          :error="$v.authCode.$error || catchError"
          :error-text='getCodeErrorText'
      />
      <ui-button @click="submit" :disabled="disabled">
        {{ $t('continue') }}
      </ui-button>
    </div>
    <ui-alert v-model="visibleAlert">
      <h2 class="ui-alert__title">{{ $t('account-successfully-deleted') }}</h2>
      <div class="ui-alert__action">
        <ui-button @click="$router.push('login')">{{ $t('good') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import UiInput from "@/components/ui/UiInput.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
import {mapActions} from "vuex";
import {email, minLength, required} from "vuelidate/lib/validators";
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
  name: "ForMobile",
  components: {UiLoader, UiAlert, UiButton, UiInput},
  validations() {
    return this.getRules
  },
  data() {
    return {
      authCode: '',
      email: '',
      visibleAlert: false,
      step: 1,
      catchError: false,
      visibleLoader: false,
    }
  },
  methods: {
    ...mapActions(['deleteUnauthorized']),
    submit() {
      this.$v.$touch()
      if (!this.$v.$anyError && this.step === 2) {
        this.deleteUnauthorized({
          email: this.email,
          authCode: this.authCode
        }).then(() => {
          this.visibleAlert = true
        }).catch(() => {
          this.catchError = true
        })
      } else if (!this.$v.$anyError && this.step === 1) {
        this.deleteUnauthorized({
          email: this.email,
        }).then(() => {
          this.visibleLoader = true
          setTimeout(() => {
            this.visibleLoader = false
          }, 1000)
          this.step = 2
        }).catch(() => {
          this.catchError = true
        })
      }
    },
  },

  computed: {
    getCodeErrorText() {
      if (!this.$v.authCode.minLength && !this.catchError) {
        return this.$t('code-must-be-5')
      }

      return this.$t('error-code')
    },
    getLoginErrorText() {
      if(!this.$v.email.email.$anyError && !this.catchError) {
        return this.$t('error-email')
      }

      return this.$t('user-does-not-exist')
    },
    getRules () {
      if (this.step === 1) {
        return {
          email: {
            required,
            email
          },
        }
      } else {
        return {
          authCode: {
            required,
            minlength: minLength(5)
          },
        }
      }
    },

    disabled() {
      return this.step === 1 ? !this.email : this.step === 2 ? !this.authCode : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-account {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F5F5;
  height: 100%;
  min-height: 100vh;
  padding: 0 20px;

  &__form {
    margin: 150px 0 30px 0;
    padding: 30px 34px 40px 34px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 434px;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    @media(max-width: 768px) {
      margin: 120px 0 30px 0;
      padding: 30px 12px 40px 12px;
    }

    &-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 30px;
    }
  }
}
</style>
